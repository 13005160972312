import React, { useState } from "react";
import {
  Grid,
  Box,
  Select,
  MenuItem,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  Card,
  FormControl,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../BPCL-Cost/BPCLCost.css";
import { CostBarChart } from "../../Graph/BPCL/Cost-bar-chart";
import { Co2BarChart } from "../../Graph/BPCL/Co2-bar-chart";
import Datepicker from "../Datepicker/Datepicker";

const BPCLCost = (props) => {
  const [selectedDateRange, setSelectedDateRange] = React.useState("Today");
  const [showAverage, setShowAverage] = useState(false);
  const [showCo2Average, setShowCo2Average] = useState(false);
  const [isAverage, setIsAverage] = React.useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "INV01",
        "INV02",
        "INV03",
        "INV04",
        "INV05",
        "INV06",
        "INV07",
        "INV08",
        "INV09",
      ],
      title: {
        text: "Inverters",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "kWh",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Inverter Output",
        data: [4000, 10000, 9000, 10000, 3000, 7000, 10000, 8000, 6000],
        color: "#9C27B0",
      },
    ],
  };

  const chartOptions2 = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "INV01",
        "INV02",
        "INV03",
        "INV04",
        "INV05",
        "INV06",
        "INV07",
        "INV08",
        "INV09",
      ],
      title: {
        text: "Inverters",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "kWh",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Inverter Output",
        data: [4000, 10000, 9000, 10000, 3000, 7000, 10000, 8000, 6000],
        color: "#9C27B0",
      },
    ],
  };

  return (
    <Grid container mt={2} className="cost-data availability-box">
      {/* Chart and Controls for Cost */}
      <Grid item xs={12} md={6} style={{ borderRight: "1px solid #f5efef" }}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>
              <b>Cost</b>
            </h5>
          </div>
          <div className="d-flex gap-4">
            <Datepicker
              startDate={props.costStartDate}
              endDate={props.costEndDate}
              setStartDate={props.setCostStartDate}
              setEndDate={props.setCostEndDate}
            />
            <Select
              value={props.costTime}
              onChange={props.handleDateCostTime}
              className="inpute-week"
            >
              <MenuItem value={1}>Today</MenuItem>
              <MenuItem value={2}>Week</MenuItem>
              <MenuItem value={3}>Month</MenuItem>
              <MenuItem value={4}>Quarter</MenuItem>
              <MenuItem value={5}>Year</MenuItem>
            </Select>
            <h6>
              Average{" "}
              <Switch
                checked={showAverage}
                onChange={(e) => setShowAverage(e.target.checked)}
              />
            </h6>
          </div>
        </div>
        <CostBarChart
          costData={
            props.costStartDate && props.costEndDate
              ? props.costDateWiseData
              : props.costData
          }
          showAverage={showAverage}
          setShowAverage={setShowAverage}
        />

        <div className="d-flex gap-3 cost-top">
          <div className="cost-box">
            Cost :{" "}
            <b>
              ₹
              {props.costStartDate && props.costEndDate
                ? props.costDateWiseData.cost_saving_count
                : props.costData?.cost_saving_count}
            </b>
          </div>
          <div className="cost-box">
            Average : <b>₹{props.costStartDate && props.costEndDate
                ? props.costDateWiseData.total_average_value : props.costData?.total_average_value}</b>
          </div>
          <div className="cost-box">
            Total Cost : <b>₹{props.costStartDate && props.costEndDate
                ? props.costDateWiseData.total_cost_saving_count : props.costData?.total_cost_saving_count}</b>
          </div>
        </div>
      </Grid>

      {/* Chart and Controls for BPCL Green */}
      <Grid item xs={12} md={6}>
        <div className="d-flex justify-content-between">
          <div>
            <h5 style={{ marginLeft: "30px" }}>
              <b>BPCL Green</b>
            </h5>
          </div>
          <div className="d-flex gap-4">
            <Datepicker
              startDate={props.co2StartDate}
              endDate={props.co2EndDate}
              setStartDate={props.setCo2StartDate}
              setEndDate={props.setCo2EndDate}
            />
            <Select
              value={props.co2Time}
              onChange={props.handleDateCo2Time}
              className="inpute-week"
            >
              <MenuItem value={1}>Today</MenuItem>
              <MenuItem value={2}>Week</MenuItem>
              <MenuItem value={3}>Month</MenuItem>
              <MenuItem value={4}>Quarter</MenuItem>
              <MenuItem value={5}>Year</MenuItem>
            </Select>
            <h6>
              Average{" "}
              <Switch
                checked={showCo2Average}
                onChange={(e) => setShowCo2Average(e.target.checked)}
              />
            </h6>
          </div>
        </div>

        <Co2BarChart
          co2Data={
            props.co2StartDate && props.co2EndDate
              ? props.co2DateWiseData
              : props.co2Data
          }
          showCo2Average={showCo2Average}
          setShowCo2Average={setShowCo2Average}
        />
        <div className="d-flex gap-3  mx-4">
          <div className="cost-box d-flex gap-1">
            CO₂: <b>{ props.co2StartDate && props.co2EndDate ? props.co2DateWiseData?.co2_count : props.co2Data?.co2_count} </b>
            <p style={{ fontSize: "10px", marginTop: "4px" }}>
              {props.co2Data?.tag_unit}
            </p>
          </div>
          <div className="cost-box d-flex gap-1">
            Average : <b>{props.co2StartDate && props.co2EndDate ? props.co2DateWiseData?.total_average_value :props.co2Data?.total_average_value} </b>
            <p style={{ fontSize: "10px", marginTop: "4px" }}>
              {props.co2Data?.tag_unit}
            </p>
          </div>
          <div className="cost-box d-flex gap-1">
            <p className="total-co">Total CO₂</p> :{" "}
            <b>{props.co2StartDate && props.co2EndDate ? props.co2DateWiseData?.total_co2_count :props.co2Data?.total_co2_count}</b>
            <p style={{ fontSize: "10px", marginTop: "4px" }}>
              {props.co2Data?.tag_unit}
            </p>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default BPCLCost;
