// utils.js
export function getLabels(inverterTime) {
    switch (inverterTime) {
      case 1:
        return { labelOne: "Today", labelTwo: "Yesterday" };
      case 2:
        return { labelOne: "This Week", labelTwo: "Last Week" };
      case 3:
        return { labelOne: "This Month", labelTwo: "Last Month" };
      case 4:
        return { labelOne: "This Quarter", labelTwo: "Last Quarter" };
      case 5:
        return { labelOne: "This Year", labelTwo: "Last Year" };
      default:
        return { labelOne: "", labelTwo: "" };
    }
  }
  