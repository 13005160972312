import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Switch,
  CardContent,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { images } from "../../../config";
import "../../../components/BPCL/BPCLGeneration/BPCLGeneration.css";
import { GenerationBarChart } from "../../Graph/BPCL/Generation-bar-chart";
import { GenerationDonutChart } from "../../Graph/BPCL/Generation-pie-chart";
import { useParams } from "react-router-dom";
import { getPlantProcessDashboardWeatherPeakData } from "../../../container/BPCL/services";
import Mqqtdata from "../../../container/ProcessDashboard/Mqqtdata";
import { SoftTagFormula } from "../../../container/SmartTag/softTag/SoftTagFormula";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { getLabels } from "../BPCL_labels";
import HalfCircleGauge from "../../Graph/BPCL/Speedometer";
import Datepicker2 from "../Datepicker/Datepicker2";
import Datepicker from "../Datepicker/Datepicker";

// Chart options
const chartOptions = {
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00"],
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "MWh",
      data: [3000, 4000, 5000, 6000, 7000, 8000, 9000],
      color: "#9C27B0",
    },
  ],
};
const label = { inputProps: { "aria-label": "Switch demo" } };

const donutChartOptions = {
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
      beta: 0,
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "72%",
    align: "center",
    verticalAlign: "middle",
    y: 20,
    style: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000",
    },
  },
  plotOptions: {
    pie: {
      innerSize: "70%",
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Data",
      data: [
        { name: "Category A", y: 55 },
        { name: "Category B", y: 45 },
      ],
    },
  ],
};

const BPCLGeneration = (props) => {
  const [showGenerationAverage, setShowGenerationAverage] = useState(false);
  const mqttDataReduce = props.sliderRealTimeTagData.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  const tag = props.weatherGraphData[0];
  const tag1 = props.weatherGraphData[1];
  const tag2 = props.weatherGraphData[2];
  const tag3 = props.weatherGraphData[3];

  console.log("props.generationData", props.generationData);
  // console.log("props.generationDateWiseData", props.generationDateWiseData);

  let tagValue = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag.device_uid && f.device_tag === tag.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag?.is_soft_tag &&
    tag?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue1 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag1.device_uid && f.device_tag === tag1.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag1?.is_soft_tag &&
    tag1?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag1.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue2 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag2.device_uid && f.device_tag === tag2.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag2?.is_soft_tag &&
    tag2?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag2.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue3 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag3.device_uid && f.device_tag === tag3.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag3?.is_soft_tag &&
    tag3?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag3.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  const findStatus = props.tagStatus1.length
    ? props.tagStatus1.find((f) => f.dvName == tag?.device_tag)
    : 0;

  const formattedTagValue =
    typeof tagValue === "number" &&
    tagValue % 1 !== 0 &&
    tagValue !== 0 &&
    tagValue !== 1
      ? tagValue.toFixed(5)
      : tagValue || tagValue;

  const formattedTagValue1 =
    typeof tagValue === "number" &&
    tagValue1 % 1 !== 0 &&
    tagValue1 !== 0 &&
    tagValue1 !== 1
      ? tagValue1.toFixed(5)
      : tagValue1 || tagValue1;

  const formattedTagValue2 =
    typeof tagValue2 === "number" &&
    tagValue2 % 1 !== 0 &&
    tagValue2 !== 0 &&
    tagValue2 !== 1
      ? tagValue2.toFixed(5)
      : tagValue2 || tagValue2;

  const formattedTagValue3 =
    typeof tagValue3 === "number" &&
    tagValue3 % 1 !== 0 &&
    tagValue3 !== 0 &&
    tagValue3 !== 1
      ? tagValue3.toFixed(5)
      : tagValue3 || tagValue3;

  const labels = getLabels(props.generationTime);

  console.log(
    "props.realWeatherData[0]",
    props.weatherGraphData[0],
    props.weatherGraphData[1],
    props.weatherGraphData[2],
    props.weatherGraphData[3]
  );
  return (
    <Box className="pt-2">
      <Card className="availability-box">
        <Typography
          variant="h6"
          className="typography_Growth mb-3"
          style={{ fontWeight: "600" }}
        >
          Generation
        </Typography>

        <Grid container spacing={2} className="custom-grid mt-3 mb-3">
          <Grid item xs={12} sm={12} md={3}>
            <div className="card_weather">
              <div>
                {" "}
                <h5 className="weather_BPCL">Active Power</h5>
              </div>
              <div className="weather-card-data">
                {" "}
                {tag && (
                  <HalfCircleGauge
                    realTimeData={tag}
                    sliderRealTimeTagData={props.mergedArray}
                    containerName={"guage_7"}
                    color={"red-green"}
                  />
                )}
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <div className="card_weather">
              <div>
                <h5 className="weather_BPCL">Reactive Power</h5>
              </div>
              <div className="weather-card-data">
                <HalfCircleGauge
                  realTimeData={tag1}
                  sliderRealTimeTagData={props.mergedArray}
                  containerName={"guage_8"}
                  color={"green-red"}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className="card_weather">
              <div>
                {" "}
                <h5 className="weather_BPCL">CUF</h5>
              </div>
              <div className="weather-card-data">
                <HalfCircleGauge
                  realTimeData={tag2}
                  sliderRealTimeTagData={props.mergedArray}
                  containerName={"guage_9"}
                  color={"red-green"}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className="card_weather">
              <div>
                {" "}
                <h5 className="weather_BPCL">PR</h5>
              </div>
              <div className="weather-card-data">
                <HalfCircleGauge
                  realTimeData={tag3}
                  sliderRealTimeTagData={props.mergedArray}
                  containerName={"guage_10"}
                  color={"red-green"}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          {/* Top Metrics */}
          {/* <>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent>
                  <div>
                    <Typography variant="subtitle2" color="textSecondary">
                      Active Power
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        color="textPrimary"
                      >
                        <b>{formattedTagValue}</b>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ ml: 0.5 }}
                        >
                          kW
                        </Typography>
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      {tag?.is_soft_tag && tag?.soft_tag_id__formula ? (
                        props.tagStatus1.find(
                          (status) =>
                            status.formula === tag?.soft_tag_id__formula
                        )?.statusValue < 0 ? (
                          <img
                            src={images.smartTagDownArrow}
                            alt="Arrow"
                            style={{ height: "36px" }}
                          />
                        ) : props.tagStatus1.find(
                            (status) =>
                              status.formula === tag?.soft_tag_id__formula
                          )?.statusValue > 0 ? (
                          <img
                            src={images.smartTagUpArrow}
                            alt="Arrow"
                            style={{ height: "36px" }}
                          />
                        ) : null
                      ) : findStatus && findStatus.statusValue < 0 ? (
                        <img
                          src={images.smartTagDownArrow}
                          alt="Arrow"
                          style={{ height: "36px" }}
                        />
                      ) : findStatus && findStatus.statusValue > 0 ? (
                        <img
                          src={images.smartTagUpArrow}
                          alt="Arrow"
                          style={{ height: "36px" }}
                        />
                      ) : null}

                      <h6 className="mt-3">
                        <b>
                          {tag?.is_soft_tag && tag?.soft_tag_id__formula
                            ? (
                                props?.tagStatus1.find(
                                  (status) =>
                                    status.formula === tag?.soft_tag_id__formula
                                )?.statusValue || 0.0
                              ).toFixed(2)
                            : findStatus
                            ? (findStatus?.statusValue || 0.0).toFixed(2)
                            : (0.0).toFixed(2)}
                          %
                        </b>
                      </h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="subtitle2" color="textSecondary">
                    Reactive Power
                  </Typography>
                  <div className="d-flex justify-content-between">
                    <div>
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        color="textPrimary"
                      >
                        <b>{formattedTagValue1}</b>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ ml: 0.5 }}
                        >
                          kW
                        </Typography>
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      {tag1?.is_soft_tag && tag1?.soft_tag_id__formula ? (
                        props.tagStatus1.find(
                          (status) =>
                            status.formula === tag1?.soft_tag_id__formula
                        )?.statusValue < 0 ? (
                          <img
                            src={images.smartTagDownArrow}
                            alt="Arrow"
                            style={{ height: "36px" }}
                          />
                        ) : props.tagStatus1.find(
                            (status) =>
                              status.formula === tag1?.soft_tag_id__formula
                          )?.statusValue > 0 ? (
                          <img
                            src={images.smartTagUpArrow}
                            alt="Arrow"
                            style={{ height: "36px" }}
                          />
                        ) : null
                      ) : findStatus && findStatus.statusValue < 0 ? (
                        <img
                          src={images.smartTagDownArrow}
                          alt="Arrow"
                          style={{ height: "36px" }}
                        />
                      ) : findStatus && findStatus.statusValue > 0 ? (
                        <img
                          src={images.smartTagUpArrow}
                          alt="Arrow"
                          style={{ height: "36px" }}
                        />
                      ) : null}

                      <h6 className="mt-3">
                        <b>
                          {tag1?.is_soft_tag && tag1?.soft_tag_id__formula
                            ? (
                                props?.tagStatus1.find(
                                  (status) =>
                                    status.formula ===
                                    tag1?.soft_tag_id__formula
                                )?.statusValue || 0.0
                              ).toFixed(2)
                            : findStatus
                            ? (findStatus?.statusValue || 0.0).toFixed(2)
                            : (0.0).toFixed(2)}
                          %
                        </b>
                      </h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="subtitle2" color="textSecondary">
                    CUF
                  </Typography>
                  <div className="d-flex justify-content-between">
                    <div>
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        color="textPrimary"
                      >
                        {formattedTagValue2}
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ ml: 0.5 }}
                        >
                          kW
                        </Typography>
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      {tag2?.is_soft_tag && tag2?.soft_tag_id__formula ? (
                        props.tagStatus1.find(
                          (status) =>
                            status.formula === tag2?.soft_tag_id__formula
                        )?.statusValue < 0 ? (
                          <img
                            src={images.smartTagDownArrow}
                            alt="Arrow"
                            style={{ height: "36px" }}
                          />
                        ) : props.tagStatus1.find(
                            (status) =>
                              status.formula === tag2?.soft_tag_id__formula
                          )?.statusValue > 0 ? (
                          <img
                            src={images.smartTagUpArrow}
                            alt="Arrow"
                            style={{ height: "36px" }}
                          />
                        ) : null
                      ) : findStatus && findStatus.statusValue < 0 ? (
                        <img
                          src={images.smartTagDownArrow}
                          alt="Arrow"
                          style={{ height: "36px" }}
                        />
                      ) : findStatus && findStatus.statusValue > 0 ? (
                        <img
                          src={images.smartTagUpArrow}
                          alt="Arrow"
                          style={{ height: "36px" }}
                        />
                      ) : null}

                      <h6 className="mt-3">
                        <b>
                          {tag2?.is_soft_tag && tag2?.soft_tag_id__formula
                            ? (
                                props?.tagStatus1.find(
                                  (status) =>
                                    status.formula ===
                                    tag2?.soft_tag_id__formula
                                )?.statusValue || 0.0
                              ).toFixed(2)
                            : findStatus
                            ? (findStatus?.statusValue || 0.0).toFixed(2)
                            : (0.0).toFixed(2)}
                          %
                        </b>
                      </h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="subtitle2" color="textSecondary">
                    PR
                  </Typography>
                  <div className="d-flex justify-content-between">
                    <div>
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        color="textPrimary"
                      >
                        {formattedTagValue3}
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ ml: 0.5 }}
                        >
                          kW
                        </Typography>
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      {tag3?.is_soft_tag && tag3?.soft_tag_id__formula ? (
                        props.tagStatus1.find(
                          (status) =>
                            status.formula === tag3?.soft_tag_id__formula
                        )?.statusValue < 0 ? (
                          <img
                            src={images.smartTagDownArrow}
                            alt="Arrow"
                            style={{ height: "36px" }}
                          />
                        ) : props.tagStatus1.find(
                            (status) =>
                              status.formula === tag3?.soft_tag_id__formula
                          )?.statusValue > 0 ? (
                          <img
                            src={images.smartTagUpArrow}
                            alt="Arrow"
                            style={{ height: "36px" }}
                          />
                        ) : null
                      ) : findStatus && findStatus.statusValue < 0 ? (
                        <img
                          src={images.smartTagDownArrow}
                          alt="Arrow"
                          style={{ height: "36px" }}
                        />
                      ) : findStatus && findStatus.statusValue > 0 ? (
                        <img
                          src={images.smartTagUpArrow}
                          alt="Arrow"
                          style={{ height: "36px" }}
                        />
                      ) : null}

                      <h6 className="mt-3">
                        <b>
                          {tag3?.is_soft_tag && tag3?.soft_tag_id__formula
                            ? (
                                props?.tagStatus1.find(
                                  (status) =>
                                    status.formula ===
                                    tag3?.soft_tag_id__formula
                                )?.statusValue || 0.0
                              ).toFixed(2)
                            : findStatus
                            ? (findStatus?.statusValue || 0.0).toFixed(2)
                            : (0.0).toFixed(2)}
                          %
                        </b>
                      </h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </> */}

          {/* Middle Section (Selects and Charts) */}
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={3}>
                {/* Dropdowns */}

                <Grid item xs={12} md={12} className="mb-5">
                  {/* Chart Title and Selectors */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      gap: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex gap-2">
                      <div>
                        <FormControl fullWidth variant="outlined" size="small">
                          <Select
                            value={props.generationValue}
                            onChange={props.handleDateGenerationValue}
                            className="amb_BPCL"
                          >
                            <MenuItem value={1}>Generation</MenuItem>
                            <MenuItem value={2}>PR</MenuItem>
                            <MenuItem value={3}>CUF</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <Typography variant="h6">AGG</Typography> */}
                      <div className="average_buttons">
                        <h6 className="average">Average</h6>
                        <Switch
                          className="averagebuttons"
                          checked={showGenerationAverage}
                          onChange={(e) =>
                            setShowGenerationAverage(e.target.checked)
                          }
                        />
                        <b>
                          {showGenerationAverage
                            ? props.generationData?.overall_average_value
                            : ""}
                        </b>
                        <h6 className="average-rate">
                          {showGenerationAverage
                            ? props.generationData?.tag_unit
                            : ""}
                        </h6>
                      </div>
                      <Datepicker
                        startDate={props.generationStartDate}
                        endDate={props.generationEndDate}
                        setStartDate={props.setGenerationStartDate}
                        setEndDate={props.setGenerationEndDate}
                      />
                    </div>
                    <div>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        className="d-flex flex-row gap-2"
                      >
                        <Select
                          value={props.generationTime}
                          onChange={props.handleDateGenerationTime}
                          className="inpute-week"
                          // sx={{ padding: "1.7rem 0" }}
                        >
                          <MenuItem value={1}>Today</MenuItem>
                          <MenuItem value={2}>Week</MenuItem>
                          <MenuItem value={3}>Month</MenuItem>
                          <MenuItem value={4}>Quarter</MenuItem>
                          <MenuItem value={5}>Year</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {/* Main Chart */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{ borderRight: "1px solid #f5efef" }}
                >
                  <GenerationBarChart
                    generationData={
                      props.generationStartDate && props.generationEndDate
                        ? props.generationDateWiseData
                        : props.generationData
                    }
                    showGenerationAverage={showGenerationAverage}
                  />
                </Grid>

                {/* Secondary Donut Chart */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  style={{ borderRight: "1px solid #f5efef" }}
                >
                  <GenerationDonutChart
                    className="genrationdunut"
                    generationData={props.generationData}
                    labels={labels}
                  />
                </Grid>

                {/* Metrics Section */}
                <Grid item xs={12} sm={12} md={3}>
                  <Box>
                    <h6 className="mt-1 invertersgenration">Generation</h6>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <button className="BPCLavailability1">
                          <h3
                            className="bpclPlant labelOne"
                            style={{ color: "black" }}
                          >
                            {labels.labelOne}
                          </h3>
                          <div className="d-flex flex-availbility">
                            <h3
                              className="plant_time"
                              style={{ color: "black" }}
                            >
                              {
                                props.generationBoxData?.generation
                                  ?.current_difference
                              }
                            </h3>
                            <span
                              className="plant_hrs"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.generation?.tag_unit}
                            </span>
                          </div>
                        </button>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <button className="BPCLavailability1">
                          <h3
                            className="bpclPlant labelTwo"
                            style={{ color: "black" }}
                          >
                            {labels.labelTwo}
                          </h3>
                          <div className="d-flex flex-availbility">
                            <h3
                              className="plant_time"
                              style={{ color: "black" }}
                            >
                              {
                                props.generationBoxData?.generation
                                  ?.past_difference
                              }
                            </h3>
                            <span
                              className="plant_hrs"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.generation?.tag_unit}
                            </span>
                          </div>
                        </button>
                      </Grid>
                    </Grid>

                    <h6 className="mt-4">PR</h6>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <button className="BPCLavailability1">
                          <h3
                            className="bpclPlant labelOne"
                            style={{ color: "black" }}
                          >
                            {labels.labelOne}
                          </h3>
                          <div className="d-flex flex-availbility">
                            <h3
                              className="plant_time"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.pr?.current_avg}
                            </h3>
                            <span
                              className="plant_hrs"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.pr?.tag_unit}
                            </span>
                          </div>
                        </button>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <button className="BPCLavailability1">
                          <h3
                            className="bpclPlant labelTwo"
                            style={{ color: "black" }}
                          >
                            {labels.labelTwo}
                          </h3>
                          <div className="d-flex flex-availbility">
                            <h3
                              className="plant_time"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.pr?.past_avg}
                            </h3>
                            <span
                              className="plant_hrs"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.pr?.tag_unit}
                            </span>
                          </div>
                        </button>
                      </Grid>
                    </Grid>

                    <h6 className="mt-4">CUF</h6>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <button className="BPCLavailability1">
                          <h3
                            className="bpclPlant labelOne"
                            style={{ color: "black" }}
                          >
                            {labels.labelOne}
                          </h3>
                          <div className="d-flex flex-availbility">
                            <h3
                              className="plant_time"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.cuf?.current_avg}
                            </h3>
                            <span
                              className="plant_hrs"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.cuf?.tag_unit}
                            </span>
                          </div>
                        </button>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <button className="BPCLavailability1">
                          <h3
                            className="bpclPlant labelTwo"
                            style={{ color: "black" }}
                          >
                            {labels.labelTwo}
                          </h3>
                          <div className="d-flex flex-availbility">
                            <h3
                              className="plant_time"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.cuf?.past_avg}
                            </h3>
                            <span
                              className="plant_hrs"
                              style={{ color: "black" }}
                            >
                              {props.generationBoxData?.cuf?.tag_unit}
                            </span>
                          </div>
                        </button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default BPCLGeneration;
