import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { CompactTagValue } from "../../CompactValue/CompactValue";

export function PieChartCostBPCL({
  countData,
  activeSwitches,
  boxCount,
  dateSelectedData,
}) {
  const chartRef = useRef(null);
  const { selectBPCLUnits } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  const validCountData = Array.isArray(countData) ? countData : [];

  const isLiveDataPresent = validCountData.some((item) => item.type === "live");

  const totalGenerationValue = validCountData.reduce(
    (acc, plant) =>
      isLiveDataPresent
        ? acc + (Math.abs(plant.value) || 0)
        : acc + plant.value,
    0
  );

  const getColorForPlant = (plantId) => {
    const activeSwitch = activeSwitches.find(
      (switchObj) => switchObj.id === plantId
    );
    return activeSwitch ? activeSwitch.color : "#9B64B7"; // Default color if not found
  };

  const dataForChart = validCountData.map((plant) => ({
    name: plant.name,
    y: parseFloat(Math.abs(plant.value)),
    color: getColorForPlant(plant.id),
    originalY: parseFloat(plant?.value),
  }));

  const options = {
    chart: {
      type: "pie",
      height: 230,
      width: 230,
      backgroundColor: "transparent",
      align: "center",
      verticalAlign: "middle",
    },
    title: {
      // text: parseFloat(
      //   CompactTagValue(selectBPCLUnits, totalGenerationValue / boxCount)
      // ),
      text: isLiveDataPresent
        ? parseFloat(totalGenerationValue / boxCount).toFixed(2)
        : dateSelectedData,
      align: "center",
      verticalAlign: "middle",
      y: 10, // Adjusted upward
      style: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#000",
      },
    },
    subtitle: {
      text: "PR%",
      align: "center",
      verticalAlign: "middle",
      y: 30, // Positioned below the title
      style: {
        fontSize: "16px",
        color: "#666",
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce(
          (acc, point) => acc + Math.abs(point.originalY),
          0
        );
        const percentage =
          ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + "%";
        return `<div style="font-size: 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${
                    this.point.color
                  }">\u25A0</span> ${this.point.originalY.toFixed(
          2
        )} PR% (${percentage})
                </div>`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Generation",
        data: dataForChart,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [countData, activeSwitches, selectBPCLUnits]); // Re-run effect when countData or activeSwitches change

  return (
    <div
      style={{
        width: "100%",
        height: "195px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
      className="doughnut-chart1"
    >
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </>
    </div>
  );
}
