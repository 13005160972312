import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { images } from "../../../config";

HC_more(Highcharts);
exporting(Highcharts);

export const Co2BarChart = React.memo(({ co2Data, showCo2Average }) => {
  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  const chartRef = useRef(null);
  const param = useParams();

  // Prepare data for main series and labels from co2Data.data
  const dataSeries = [
    {
      name: "Tag Value",
      data: co2Data?.data?.map((item) => parseFloat(item.tag_value)) || [],
      color: "#50C878",
    },
  ];

  // Conditionally add the average series if showCo2Average is true
  if (showCo2Average) {
    dataSeries.push({
      name: "Average Value",
      data: co2Data?.data?.map((item) => parseFloat(item.average_value)) || [],
      type: "line",
      color: "green",
      dashStyle: "Dash",
      marker: {
        enabled: false,
      },
    });
  }

  const labels = co2Data?.data?.map((item) => item.MIN_KEY) || [];

  const options = {
    chart: {
      type: "column",
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          if (labels.length >= 5) {
            const visibleCategories = labels.slice(-5);
            xAxis.setExtremes(
              labels.length - visibleCategories.length,
              labels.length - 1,
              false
            );
          }
          this.redraw();
        },
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      title: {
        text: null,
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: dataSeries,
    scrollbar: {
      enabled: true,
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const pointIndex = this.point.index;
        const currentData = co2Data?.data?.[pointIndex];
        let tooltipContent = `<b>${currentData?.MIN_KEY}</b><br/>Value: ${currentData?.tag_value}`;

        if (showCo2Average && currentData?.average_value) {
          tooltipContent += `<br/>Average Value: ${currentData.average_value}`;
        }

        if (currentData?.recipe_data) {
          tooltipContent += `<br/><b>Recipe Data:</b><br/><table style="width: 100%; border-collapse: collapse;">`;
          currentData.recipe_data.forEach((recipe) => {
            tooltipContent += `<tr><td>${recipe.recipe_name}</td><td>${recipe.tag_value}</td></tr>`;
          });
          tooltipContent += `</table>`;
        }
        return tooltipContent;
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart && labels.length >= 5) {
      chart.options.xAxis.min = labels.length - 5;
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [co2Data]);

  if (!co2Data || co2Data.length === 0) {
    // No data found
    return (
      <div style={{ height: "395px" }}>
        <img
          src={images.noDataFoundImg}
          className="notFoundDataImg"
          alt="No Data Found Img"
          style={{ transform: "translate(-50px, 50px)" }}
        />
      </div>
    );
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </>
  );
});

// export function Co2BarChart({ co2Data, showCo2Average }) {
//   const { selectUnit } = useSelector((state) => state.dateSliceprocessDashboardState);
//   const chartRef = useRef(null);
//   const param = useParams();

//   // Prepare data for main series and labels from co2Data.data
//   const dataSeries = [{
//     name: 'Tag Value',
//     data: co2Data?.data?.map(item => parseFloat(item.tag_value)) || [],
//     color: "#50C878"
//   }];

//   // Conditionally add the average series if showCo2Average is true
//   if (showCo2Average) {
//     dataSeries.push({
//       name: 'Average Value',
//       data: co2Data?.data?.map(item => parseFloat(item.average_value)) || [],
//       type: 'line',
//       color: 'green',
//       dashStyle: 'Dash',
//       marker: {
//         enabled: false
//       },

//     });
//   }

//   const labels = co2Data?.data?.map(item => item.MIN_KEY) || [];

//   const options = {
//     chart: {
//       type: 'column',
//       events: {
//         load: function () {
//           const xAxis = this.xAxis[0];
//           if (labels.length >= 5) {
//             const visibleCategories = labels.slice(-5);
//             xAxis.setExtremes(labels.length - visibleCategories.length, labels.length - 1, false);
//           }
//           this.redraw();
//         },
//       },
//     },
//     title: {
//       text: null,
//     },
//     legend: {
//         enabled: false,
//       },
//     xAxis: {
//       categories: labels,
//     },
//     yAxis: {
//       title: {
//         text: null,
//       },
//       stackLabels: {
//         enabled: false,
//         style: {
//           fontWeight: 'bold',
//           color: 'gray',
//         },
//       },
//     },
//     plotOptions: {
//       series: {
//         stacking: 'normal',
//         dataLabels: {
//           enabled: false,
//         },
//       },
//     },
//     series: dataSeries,
//     scrollbar: {
//       enabled: true,
//     },
//     exporting: {
//       enabled: true,
//     },
//     credits: {
//       enabled: false,
//     },
//     tooltip: {
//       useHTML: true,
//       formatter: function () {
//         const pointIndex = this.point.index;
//         const currentData = co2Data?.data?.[pointIndex];
//         let tooltipContent = `<b>${currentData?.MIN_KEY}</b><br/>Value: ${currentData?.tag_value}`;

//         if (showCo2Average && currentData?.average_value) {
//           tooltipContent += `<br/>Average Value: ${currentData.average_value}`;
//         }

//         if (currentData?.recipe_data) {
//           tooltipContent += `<br/><b>Recipe Data:</b><br/><table style="width: 100%; border-collapse: collapse;">`;
//           currentData.recipe_data.forEach(recipe => {
//             tooltipContent += `<tr><td>${recipe.recipe_name}</td><td>${recipe.tag_value}</td></tr>`;
//           });
//           tooltipContent += `</table>`;
//         }
//         return tooltipContent;
//       },
//     },
//   };

//   useEffect(() => {
//     const chart = chartRef.current?.chart;
//     if (chart && labels.length >= 5) {
//       chart.options.xAxis.min = labels.length - 5;
//       chart.options.xAxis.max = labels.length - 1;
//       chart.redraw();
//     }
//   }, [co2Data]);

//   return (
//     <>
//       { co2Data ? <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} /> : <img src={images.noDataFoundImg} className="notFoundDataImg" alt="No Data Found Img" /> }
//     </>
//   );
// }
