import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CompactTagValue } from "../../CompactValue/CompactValue";
import { useSelector } from "react-redux";

export function BarChartCostBPCL({ countData, activeSwitches }) {
  const chartRef = useRef(null);
  const { selectBPCLUnits } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  console.log("countDatacountDatacountDatacountData", countData);

  const getColorForPlant = (plantId) => {
    const activeSwitch = activeSwitches.find(
      (switchObj) => switchObj.id === plantId
    );
    return activeSwitch ? activeSwitch.color : "#9B64B7";
  };

  const dataWithColors = countData.map((plant) => ({
    y: parseFloat(Math.abs(plant.value)),
    color: getColorForPlant(plant.id),
  }));

  const categories = countData?.map((plant) => plant.name);

  console.log("categories", categories);

  const options = {
    chart: {
      type: "column",
      height: 230,
      width: 230,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categories,
      labels: {
        enabled: false,
      },

      max: 4,
    },
    scrollbar: {
      enabled: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "PR%",
      },
    },
    series: [
      {
        name: "Performance",
        data: dataWithColors,
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [countData, activeSwitches, selectBPCLUnits]);

  return (
    <div
      style={{
        width: "100%",
        height: "195px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
}
