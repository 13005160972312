import React, { useEffect, useState } from "react";
import Mqqtdata from "../../../ProcessDashboard/Mqqtdata";
import { timeStampCheckStatus } from "../../../../components/TimeStampCheckStatus/timeStampCheckStatus";
import { toast } from "react-toastify";


const OverViewMachineMqttData = ({
  topics,
  setMqttTagPreviousvalue,
  setSliderRealTimeTagData,
  tagListDataGroupAndList,
  mqttTagData,
  setMqttTagData
}) => {

  const [isNotified, setIsNotified] = useState(false);
  const [MqttTime, setMqttTime] = useState("");

  

  useEffect(() => {
    const mergedArray = [].concat(...Object.values(mqttTagData));

    if (tagListDataGroupAndList.length) {
        const filteredArray = mergedArray.filter((obj) => {
            const device = obj.device__uid;
            const deviceTag = obj.device_tag;
        
            return tagListDataGroupAndList.some(
              (item) =>
                item?.machineUID == device &&
                item?.dvName == deviceTag
            );
          });
          setSliderRealTimeTagData(filteredArray);

      setMqttTagPreviousvalue((prev) => {
        const prevValue = [...prev].map((row) => {
          const findTag = filteredArray.find(
            (item) => item.device_tag == row.dvName
          );
          if (findTag) {
            const checkTimeData = findTag.tagDateAndTime === row.dateAndTime;
            if (checkTimeData) {
              return row;
            }
            return {
              dvName: findTag.device_tag,
              prevValue: row.curValue,
              curValue: findTag.tagValue,
              dateAndTime: findTag.tagDateAndTime,
            };
          }
          return row;
        });


        return prevValue;
      });
    }
  }, [mqttTagData]);

  const convertTimeStampFormat = mqttTagData ? Object.keys(mqttTagData).map((row) =>{
    const findTimeStamp = mqttTagData[row].find((f) => f.device_tag == "TIMESTAMP");
    return ({
      ...findTimeStamp,
      TIMESTAMP: findTimeStamp?.TIMESTAMP || "",
    });
  }) : []

  useEffect(() => {
    const getData = timeStampCheckStatus(convertTimeStampFormat);

    if (!isNotified && Array.isArray(getData) && getData.length > 0) {
      const { device_tag, tagDateAndTimeFormat, tagValue } = getData[0];
      setMqttTime(tagValue);
      const notifyError = (message) =>
        toast.error(message, {
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
          toastClassName: 'custom-toast',
        });

        if (device_tag && tagDateAndTimeFormat && tagValue) {
      
          notifyError(
            <div>
              <p>Device Tag: {device_tag}</p>
              <p>Current Time: {tagDateAndTimeFormat}</p>
              <p>Mqtt Time: {tagValue}</p>
            </div>
          );
          setIsNotified(true); 
        }
    }
  }, [mqttTagData, isNotified]); 
  

  return (
    <>
      <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} />
    </>
  );
};

export default OverViewMachineMqttData;
