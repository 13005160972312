import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../../../container/ProcessDashboard/ProcessDashboard.css";
import { images } from "../../../config";

export function InverterDonutChart({ inverterData, labels }) {
  console.log("inverterData", inverterData);
  const chartRef = useRef(null);

  const options = {
    chart: {
      type: "pie",
      height: 250,
      width: 250,
      backgroundColor: "transparent",
    },
    title: {
      text: (inverterData.current_total + inverterData.past_total).toFixed(2),
      align: "center",
      verticalAlign: "middle",
      y: 20,
      style: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#000",
      },
    },
    subtitle: {
      text: inverterData?.tag_unit,
      align: "center",
      verticalAlign: "middle",
      y: 35,
      style: {
        fontSize: "12px",
        color: "#666",
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + point.y, 0);
        const percentage = ((this.y / total) * 100).toFixed(2) + "%";
        const index = this.point.index;
        const deviceName = index === 0 ? labels.labelOne : labels.labelTwo;
        const deviceColor = index === 0 ? "#9A64B2" : "#D2D5DB";
        const originalValue =
          index === 0 ? inverterData.current_total : inverterData.past_total;

        return `<div style="font-size: 12px">
                          <b>${deviceName}</b><br/>
                          <span style="color:${deviceColor}">\u25A0</span> ${originalValue} (${percentage})
                        </div>`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Values",
        data: [
          {
            name: "Current Total22",
            y:
              inverterData && inverterData.current_total !== undefined
                ? Math.abs(inverterData.current_total)
                : 0,
            color: "#9A64B2",
          },
          {
            name: "Past Total",
            y:
              inverterData && inverterData.past_total !== undefined
                ? Math.abs(inverterData.past_total)
                : 0,
            color: "#D2D5DB",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    if (chartRef.current && inverterData) {
      chartRef.current.chart.update(options);
    }
  }, [inverterData]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "230px",
        transform: "translate(0px, 80px)",
      }}
      className="doughnut-chart1"
    >
      {inverterData &&
      inverterData.current_total !== undefined &&
      inverterData.past_total !== undefined ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      ) : (
        <img
          src={images.noDataFoundImg}
          className="notFoundDataImg not-found"
          alt="No data found"
        />
      )}
    </div>
  );
}
