import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const Datepicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
  // Step 2: Initialize state for startDate and endDate

  console.log("startDate", startDate);

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // Step 3: Update state when date range changes
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);

      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      // Clear the dates if the range is cleared
      setStartDate(null);
      setEndDate(null);
      console.log("Clear");
    }
  };

  const rangePresets = [
    { label: "Today", value: [dayjs(), dayjs()] },
    { label: "Yesterday", value: [dayjs().subtract(1, "day"), dayjs()] }, 
    { label: "Last Week", value: [dayjs().subtract(1, "week"), dayjs()] },
    { label: "Last Month", value: [dayjs().subtract(1, "month"), dayjs()] },
    { label: "Last Quarter", value: [dayjs().subtract(3, "months"), dayjs()] },
  ];
  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <div className="range-picker-container">
      <RangePicker presets={rangePresets} onChange={onRangeChange}  disabledDate={disableFutureDates}/>
      {/* Display selected dates */}
    </div>
  );
};

export default Datepicker;
